export const AuequitexMixins = {
    methods: {
        calculateRoiPercentage (invoice) {

            const scoring = invoice.scorings[0];


            if(invoice.is_reverse == 1) {

                // let roi_percent= scoring.discount_in_percentage * 1 + scoring.fee_hive * 1
                // let roi = (scoring.amount / 100) * roi_percent
                // let finally_roi_percent = roi * 100 / scoring.amount_with_discount

                // return finally_roi_percent.toFixed(2)
                return Number(
                    scoring.discount_in_percentage * 1 + scoring.fee_hive * 1
                    // scoring.discount_in_percentage
                ).toFixed(2);
            } else {

                // let roi_percent = scoring.discount_in_percentage * 1 - scoring.fee_hive * 1
                //
                // let roi = (scoring.amount / 100) * roi_percent
                // let finally_roi_percent = roi * 100 / scoring.amount_with_discount
                //
                // return finally_roi_percent.toFixed(2)

                return Number(
                    scoring.discount_in_percentage * 1 - scoring.fee_hive * 1
                    // scoring.discount_in_percentage
                ).toFixed(2);
            }
        },
        dueDaysLeft(due_date) {
            const targetDate = new Date(due_date),
                currentDate = new Date(),
                differenceInTime = targetDate.getTime() - currentDate.getTime(),
                differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
            return differenceInDays && differenceInDays >= 0 ? differenceInDays : 0;
        },
        twoDecimalNumber(number) {
            return parseFloat(parseFloat(number).toFixed(2));
        }
    }
};